import { useAppContext } from '../../context/Context'
import { getLinkFile } from './DisplayRagModal'
import MarkDownFormater from './MarkDownFormater'

export default function RagContentDisplay({ chunk, source, source_id, page, index = null }) {
    const [, dispatch] = useAppContext();

    return (
        <div className='p-4'>
            <div className='w-full py-3 border-b text-sm'>
                <div className='flex flex-row gap-2'>
                    <div className='font-light'>Fichier :</div>
                    <div onClick={() => getLinkFile(source_id, dispatch)} className='font-semibold hover:underline cursor-pointer'>{source}</div>
                </div>
                <div><span className='font-light'>Page :</span> <span className='font-semibold'>{page}</span></div>
            </div>
            <div className='text-sm pt-2'>
                <MarkDownFormater>
                {`[...]\n${chunk}\n[...]`}
                </MarkDownFormater>
            </div>
        </div>
    )
}
