import React from 'react';
import {Routes, Route, NavLink, Navigate} from 'react-router-dom';
import {useAppContext} from "../../../context/Context";
import TitleAndSubTitle from '../settings/TitleAndSubTitle';
import { twMerge } from 'tailwind-merge';
import Affichage from './Affichage/Affichage';
import Companies from "./companies/Companies";
import "./Technical.css";

const Technical: React.FC = () => {
    const [{ isAdminVirteem },] = useAppContext();

    return (
        <main className="main-technical">
            {isAdminVirteem &&
            <>
                <TitleAndSubTitle h1="Outils de Maintenance" h2="Paramétrage avancé, débogage et maintenance de Virteem Companion" />
                <div className="technical-container">
                    {/* Navigation menu with links to Affichage and Companies pages */}
                    <nav className="technical-nav">
                        <ul>
                            <li>
                                <NavLink to="affichage"
                                         className={twMerge(`${({isActive}) => (isActive ? "active" : "")} w-full`)}
                                         end>Affichage</NavLink> {/* Link to Affichage page */}
                            </li>
                            <li>
                                <NavLink to="companies"
                                         className={twMerge(`${({isActive}) => (isActive ? "active" : "")} w-full`)}
                                         end>Entreprises</NavLink> {/* Link to Companies page */}
                            </li>
                        </ul>
                    </nav>

                    {/* Define routes for the technical section */}
                    <div className="technical-content">
                        <Routes>
                            <Route path="affichage" element={<Affichage/>}/>
                            <Route path="companies" element={<Companies/>} />
                            <Route path="/" element={<Navigate to="/administration/admin-technical/affichage" />} /> {/* Default route */}*
                        </Routes>
                    </div>
                </div>
            </>
            }
        </main>
    );
}
export default Technical;