import Category from "../../settings/companyAdministration/Category";
import ActionContainer from "../../settings/companyAdministration/ActionContainer";
import {ACTION, GENERIC_MODAL_ACTIONS} from "../../../../context/actionTypes";
import React from "react";
import {useAppContext} from "../../../../context/Context";
import {BsArrowRepeat} from "react-icons/bs";
import TitleAndSubTitle from "../../settings/TitleAndSubTitle";

export default function Companies() {
    const [, dispatch] = useAppContext();

    return <main className="flex flex-col gap-2">
        <TitleAndSubTitle h1="Documents" h2="Effectuez des actions de maintenance sur les documents"/>

        <Category title="Documents">
            <ActionContainer text="Régénérer les embeddings de tous les documents d'une entreprise">
                <button
                    className="btn-transition-l bg-perso-blue hover:bg-opacity-75 min-w-fit"
                    onClick={() => {
                        dispatch({
                            type: ACTION.SET_GENERIC_MODAL,

                            payload: {
                                action: GENERIC_MODAL_ACTIONS.RE_EMBED_DOCUMENTS,
                            },
                        });
                    }}
                >
                    <BsArrowRepeat className="h-5 w-5"/>
                    <div>Régénérer les embeddings</div>
                </button>
            </ActionContainer>
        </Category>
    </main>;
}