import useChat from '../../hooks/chat/useChat'
import { IoArrowUp } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import { CHAT_MODE } from '../../context/actionTypes';
import { Tooltip } from 'react-tooltip';
import MessagesDisplayer from './MessagesDisplayer';
import SearchModeDisplayer from './SearchModeDisplayer';
import ConfirmModal from '../../utils/ConfirmModal';
import BurgerMenu from './BurgerMenu';
import ContentMenu from './ContentMenu';
import { FaTrashAlt } from 'react-icons/fa';
// import backgroundImage from '../../../images/background.svg'; // Adjust the path as necessary
import backgroundImage from './background.svg';
import {isChatMode} from "./fonctions";

export default function Chat() {
    const [
        { message, messages, chatContainerRef, bottomRef, isLargerThanInitial, withHistory, hasLLMChatPureOption, isLoading, mode, selectedMessage, searchResponse, confirmModal, firstText },
        { handleKeyDown, handleMessageChange, autoAdaptTextArea, handleSend, createFeedback, handleRetry, setMode, setSelectedMessage, deleteConversation, displayRag, setConfirmModal, toggleHistory, dispatch }
    ] = useChat()
    return (
        <>
            <div className='flex items-center w-screen h-screen overflow-hidden mx-auto'>
                <BurgerMenu>
                    <ContentMenu
                        withHistory={withHistory}
                        toggleHistory={toggleHistory}
                        hasLLMChatPureOption={hasLLMChatPureOption}
                        mode={mode}
                        setMode={setMode}
                    />
                </BurgerMenu>
                <div className={`relative flex flex-shrink flex-col max-h-screen gap-4 w-full h-full mx-auto pb-4 px-4 lg:px-0`}
                    style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='absolute left-0 top-0 w-full h-12 bg-perso-gray z-10 flex justify-end items-center shadow shadow-perso-purple lg:hidden'>
                        <img src='/images/logo-black.svg' alt='logo' className='h-8 ' />
                    </div>
                    <div className='flex-grow overflow-y-auto overflow-x-hidden flex gap-4 pb-4 h-screen pt-12 z-0 ' ref={chatContainerRef}>
                        {(isChatMode(mode)) ? (
                            <MessagesDisplayer
                                messages={messages}
                                createFeedback={createFeedback}
                                dispatch={dispatch}
                                handleRetry={handleRetry}
                                selectedMessage={selectedMessage}
                                setSelectedMessage={setSelectedMessage}
                                isLoading={isLoading}
                                bottomRef={bottomRef}
                                chatContainerRef={chatContainerRef}
                                displayRag={displayRag}
                                firstMessage={firstText}
                            />
                        ) : (
                            <SearchModeDisplayer searchResponse={searchResponse} isLoading={isLoading} />
                        )}

                    </div>
                    <div className='place-content-end  flex gap-2 items-center justify-center w-full lg:w-3/4 mx-auto '>
                        <div
                            className={` relative  w-full border border-gray-400 shadow rounded-2xl transform duration-300 flex items-center overflow-hidden `}>
                            <textarea
                                onKeyDown={handleKeyDown}
                                ref={message}
                                onInput={autoAdaptTextArea}
                                onChange={handleMessageChange}
                                className={`py-4 pr-12 text-xs sm:text-base px-3 flex-grow w-full outline-none max-h-48 bg-transparent resize-none overflow-y-auto bg-white text-black`}
                                tabIndex={0}
                                placeholder="Entrez votre message..."
                                rows={1}
                            />
                            <div
                                className={twMerge(`${isLargerThanInitial ? ' items-end' : ' items-center'} absolute h-full top-0 right-0 flex p-2`)}>
                                <button disabled={message.current?.value.trim() === '' || isLoading}
                                        className={twMerge(`cursor-pointer h-8 w-8 rounded-lg disabled:opacity-50 disabled:pointer-events-none bg-gray-500 disabled:hover:bg-none hover:bg-gray-600 transform duration-500 flex items-center justify-center`)}
                                        onClick={handleSend}>
                                    <IoArrowUp className="  h-5 w-5 text-white"/>
                                </button>
                            </div>
                        </div>
                        {isChatMode(mode) && messages.length > 1 && (
                            <button onClick={() => deleteConversation()}
                                 className='group hover:text-red-400 rounded-2xl  p-4  border bg-white text-gray-600 border-gray-400 cursor-pointer'>
                                <FaTrashAlt className='w-5 h-5'/>
                            </button>
                        )}
                    </div>
                    <Tooltip
                        id="chat-tooltip"
                        style={{fontSize: "0.75rem", zIndex: 9999}}
                        place='left'
                    />
                </div>
            </div>
            {confirmModal && (
                <ConfirmModal text={confirmModal.text} yes={confirmModal.yes} no={() => setConfirmModal(null)} />
            )}
        </>
    )
}