import React from "react";
import "./TitleAndSubTitle.css";
interface TitleAndSubTitleProps {
  h1: string;
  h2? : string
}

const TitleAndSubTitle: React.FC<TitleAndSubTitleProps> = ({ h1, h2 }) => {
     return (
         <div className="flex flex-col gap-0 title-component">
             <h1>{h1}</h1>
             {h2 && <h2>{h2}</h2>}
         </div>
     );
}
export default TitleAndSubTitle;