export enum ACTION {
    LOAD_SESSION = 'loadSession',
    LOGOUT = 'logout',
    LOGIN = 'login',
    UPDATE_SIGN_FORM = 'updateSignForm',
    SET_GENERIC_MODAL = 'setGenericModal',
    SETBOTTOMBAR = 'setBottomBar',
    SET_IS_VIRTEEM_ADMIN = 'setIsVirteemAdmin',
    SET_IS_ADMIN = "setIsAdmin",
    AWAITING_AUTH="setAwaitingAuth"
}

export enum GENERIC_MODAL_ACTIONS {
    CREATE_OR_UPDATE_ADMIN = 'createOrUpdateAdmin',
    USER_DELETE = 'userDelete',
    MANAGE_OPTIONS = 'manageOptions',
    CREATE_OR_UPDATE_COMPANY = 'createOrUpdateCompany',
    DELETE_REQUESTS = 'deleteRequests',
    DELETE_SENSITIVE_DATA_REQUESTS = 'deleteSensitiveDataRequests',
    DELETE_COMPANY = 'deleteCompany',
    UPLOAD_DOCUMENT = 'uploadDocuments',
    DELETE_DOCUMENT = 'deleteDocument',
    DOWNLOAD_DOCUMENT = 'downloadDocument',
    CREATE_OR_UPDATE_OPTION = 'createOrUpdateOption',
    DELETE_OPTION = 'deleteOption',
    MANAGE_COMPANY_OPTIONS = 'manageCompanyOptions',
    MANAGE_COMPANY_PLAN = 'manageCompanyPlan',
    CREATE_OR_UPDATE_PLAN = 'createOrUpdatePlan',
    DELETE_PLAN = 'deletePlan',
    CREATE_FEEDBACK = 'createFeedback',
    REPLY_FEEDBACK = 'replyFeedback',
    CREATE_OR_UPDATE_SALES_REP = 'createOrUpdateSalesRep',
    MANAGE_COMPANY_SALES = 'manageCompanySales',
    DELETE_SALES_REP="deleteSalesRep",
    DISPLAY_RAG = 'displayRag',
    DELETE_FEEDBACKS = "deleteFeedbacks",
    CREATE_OR_UPDATE_API_KEY = "createOrUpdateApiKey",
    DELETE_API_KEY = "deleteApiKey",
    RE_EMBED_DOCUMENTS = "reEmbedDocuments",
}

export enum BOTTOM_BAR_SEVERITY {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    PROCESSING = 'processing',
}

export enum MESSAGE_TYPE {
    HUMAN = 'user',
    MACHINE = 'assistant',
}

export enum CHAT_MODE {
    QUERY = 'llm',
    SEARCH = 'doc_search',
    QUERY_WITH_HISTORY = "llm_chat",
    QUERY_WITHOUT_RAG = "llm_without_rag",
}