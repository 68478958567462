import { Fragment } from 'react'
import { useAppContext } from '../context/Context'
import { GENERIC_MODAL_ACTIONS } from '../context/actionTypes'
import CreateOrUpdateAdminModal from './administration/manageUsers/CreateOrUpdateAdminModal'
import DeleteAdminModal from './administration/manageUsers/DeleteAdminModal'
import CreateOrUpdateCompanyModal from './administration/manageCompanies/CreateOrUpdateCompanyModal'
import DragAndDropDocumentsModal from './administration/documents/DragAndDropDocumentsModal'
import CreateOrUpdateOptionModal from './administration/manageOptions/CreateOrUpdateOptionModal'
import ManageCompanyOptions from './administration/manageCompanies/ManageCompanyOptions'
import DeleteCompanyModal from './administration/manageCompanies/DeleteCompanyModal'
import DeleteDocumentModal from './administration/documents/DeleteDocumentModal'
import CreateOrUpdatePlanModal from './administration/managePlans/CreateOrUpdatePlanModal'
import ManageCompanyPlan from './administration/manageCompanies/ManageCompanyPlan'
import FeedBackModal from './chat/FeedBackModal'
import ReplyFeedbackModal from './administration/manageFeedbacks/ReplyFeedbackModal'
import CreateOrUpdateSalesRepModal from './administration/manageSalesRep/CreateOrUpdateSalesRepModal'
import ManageCompanySalesModal from './administration/manageCompanies/ManageCompanySalesModal'
import DisplayRagModal from './chat/DisplayRagModal'
import DeletePlanModal from './administration/managePlans/DeletePlanModal'
import DeleteOptionModal from './administration/manageOptions/DeleteOptionModal'
import DeleteRequestsModal from './administration/manageRequests/DeleteRequestsModal'
import DeleteFeedbacksModal from './administration/settings/companyAdministration/DeleteFeedbacksModal'
import DeleteSensitiveDataModel from './administration/manageRequests/DeleteSensitiveDataModel'
import CreateOrUpdateApiKeyModal from "./administration/settings/api_keys/CreateOrUpdateApiKeyModal";
import DeleteApiKeyModal from "./administration/settings/api_keys/DeleteApiKeyModal";
import ReEmbedDocumentsModal from "./administration/manageTechnical/companies/ReEmbedDocumentsModal";
import DeleteSalesRepModal from './administration/manageSalesRep/DeleteSalesRepModal'


/** In charge of displaying different modals (modal dialog boxes) depending on the current action specified in the application context.
 *
 * @constructor
 */
export default function ModalDisplayer() {
    const [{ genericModalAction }, _] = useAppContext();

    console.log("Current genericModalAction:", genericModalAction);

    const displayComponent = () => {
        switch (genericModalAction?.action) {
            case GENERIC_MODAL_ACTIONS.USER_DELETE:
                return <DeleteAdminModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_ADMIN:
                return <CreateOrUpdateAdminModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_COMPANY:
                return <CreateOrUpdateCompanyModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_COMPANY:
                return <DeleteCompanyModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.UPLOAD_DOCUMENT:
                return <DragAndDropDocumentsModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_OPTION:
                return <CreateOrUpdateOptionModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_OPTIONS:
                return <ManageCompanyOptions {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_DOCUMENT:
                console.log("Displaying DeleteDocumentModal with props:", genericModalAction.props);
                return <DeleteDocumentModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_PLAN:
                return <CreateOrUpdatePlanModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_PLAN:
                return <ManageCompanyPlan {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_FEEDBACK:
                return <FeedBackModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.REPLY_FEEDBACK:
                return <ReplyFeedbackModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_SALES_REP:
                return <CreateOrUpdateSalesRepModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.MANAGE_COMPANY_SALES:
                return <ManageCompanySalesModal {...genericModalAction.props} />
                case GENERIC_MODAL_ACTIONS.DELETE_SALES_REP:
                    return <DeleteSalesRepModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_REQUESTS:
                return <DeleteRequestsModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_SENSITIVE_DATA_REQUESTS:
                return <DeleteSensitiveDataModel {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_FEEDBACKS:
                return <DeleteFeedbacksModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DISPLAY_RAG:
                return <DisplayRagModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_PLAN:
                return <DeletePlanModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_OPTION:
                return <DeleteOptionModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_API_KEY:
                return <CreateOrUpdateApiKeyModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.DELETE_API_KEY:
                return <DeleteApiKeyModal {...genericModalAction.props} />
            case GENERIC_MODAL_ACTIONS.RE_EMBED_DOCUMENTS:
                return <ReEmbedDocumentsModal {...genericModalAction.props} />
            default:
                console.log("No valid action for modal display.");
                return <Fragment />
        }
    }

    return (
        <div>
            {displayComponent()}
        </div>
    )
}
