import React from "react";
import {FiX} from "react-icons/fi";
import {twMerge} from "tailwind-merge";

/**
 * Props for the GenericModal component.
 *
 * @param children - The content of the modal.
 * @param toggleMe - The function to toggle the modal.
 * @param className - (Optional) The className to be added to the modal.
 * @param exitClick - (Optional) A boolean to indicate if the modal should close when the user clicks outside of it.
 * @param showCloseButton - (Optional) A boolean to indicate if the modal should show a close button.
 */
interface GenericModalProps {
    children: React.ReactNode;
    toggleMe?: () => void;
    className?: string;
    exitClick?: boolean;
    showCloseButton?: boolean;
    blurBackGround?: boolean;
}

export default function GenericModal(
    {children, toggleMe, className = "", exitClick = false, showCloseButton = false, blurBackGround = false}: GenericModalProps,
) {
    return <div
        onClick={() => exitClick && toggleMe()}
        className={twMerge(`fixed h-screen w-screen flex justify-center items-center inset-0 z-[3000] bg-black bg-opacity-20 ${blurBackGround && " backdrop-blur-sm "}`)}
    >
        <div
            onClick={(e) => e.stopPropagation()}
            className={twMerge(`relative max-h-3/4 overflow-y-auto rounded-lg shadow-lg bg-white text-base text-black mx-4 w-full lg:w-1/2 2xl:w-1/3 ${className}`)}>
            {showCloseButton && (
                <button
                    className="absolute top-2 right-2 p-1 text-gray-600"
                    onClick={() => toggleMe()}
                ><FiX/></button>
            )}
            {children}
        </div>
    </div>;
}