import React from "react";
import useAffichage from "./useAffichage";
import Category from "../../settings/companyAdministration/Category";
import ActionContainer from "../../settings/companyAdministration/ActionContainer";
import TitleAndSubTitle from "../../settings/TitleAndSubTitle";

export default function Affichage() {
    const [{percentages}, {setshowPercentages}] = useAffichage();
    return (
        <main className="flex flex-col gap-2">
            <TitleAndSubTitle h1="Affichage"/>

            <Category title="Gérez vos options d'affichages.">
                <ActionContainer text="Afficher les pourcentages de similarités avec les sources">
                    <input
                        type="checkbox"
                        checked={percentages}
                        onClick={setshowPercentages}
                    />
                </ActionContainer>
            </Category>
        </main>
    );
};
