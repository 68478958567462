import { Routes, Route, Navigate } from 'react-router-dom';
import Documents from './documents/Documents';
import Clients from './Clients';
import Tutos from './Tutos';
import Dashboard from './dashboard/Dashboard';
import useAuthentication from '../../hooks/useAuthentication';
import SignIn from './SignIn';
import Users from './manageUsers/Users';
import Options from './manageOptions/Options';
import Companies from './manageCompanies/Companies';
import Plan from './managePlans/Plan';
import { useAppContext } from '../../context/Context';
import ManageFeedbacks from './manageFeedbacks/ManageFeedbacks';
import ManageSalesReps from './manageSalesRep/ManageSalesReps';
import BurgerMenu from '../chat/BurgerMenu';
import Menu from './Menu';
import Settings from "./settings/Settings";
import backgroundImage from "../chat/background.svg";
import Home from "./settings/home/Home";
import Technical from './manageTechnical/Technical';

/** UI Component
 *
 * @constructor
 */
export default function Administration() {
    const [_, { logOut }] = useAuthentication()
    const [{ isAdminVirteem, user,isAdmin }, ] = useAppContext()
    console.debug("Administration", isAdminVirteem);
    console.debug("Administration2", isAdmin);

    console.debug("user", user);
    return (
        <div className='h-screen flex w-full'>
            {/* <Menu logOut={logOut} /> */}
            <BurgerMenu>
                <Menu logOut={logOut} company_uuid={user?.company?.uuid || ''} />
                {/*<Menu logOut={logOut} company_uuid={user?.company?.uuid || ''} />*/}
            </BurgerMenu>
            <div className='w-full bg-gray-200 overflow-auto pt-12 ' id='mainWindow' style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Routes>
                    <Route path="/" element={isAdmin ?<Dashboard />: <Navigate to="/administration/" />} />
                    <Route path="/documents" element={isAdmin ?<Documents /> : <Navigate to="/administration/"/> }/>
                    <Route path="/clients" element={<Clients />} />
                    <Route path="/feedbacks" element={isAdmin ?<ManageFeedbacks />: <Navigate to="/administration/" />} />
                    <Route path="/tutos" element={<Tutos />} />
                    <Route path="/admin-commercial-virteem" element={isAdminVirteem ? <ManageSalesReps /> : <Navigate to="/administration/" />} />
                    <Route path="/admin-plan" element={isAdminVirteem ? <Plan /> : <Navigate to="/administration/" />} />
                    <Route path="/admin-companies" element={isAdminVirteem ? <Companies /> : <Navigate to="/administration/" />} />
                    <Route path="/admin-options" element={isAdminVirteem ? <Options /> : <Navigate to="/administration/" />} />
                    <Route path="/admin-users" element={isAdminVirteem ? <Users /> : <Navigate to="/administration/" />} />
                    <Route path="/admin-technical/*" element={isAdminVirteem ? <Technical /> : <Navigate to="/administration/" />} />
                    {/* Define routes for the settings section */}
                    <Route path="/settings/*" element={<Settings/>} />
                    {/*<Route path="/settings/home" element={ <Navigate to="/administration/settings" />} />*/} {/*Open a new page and is not what we wish*/}
                </Routes>
            </div>


            <SignIn />
        </div>
    );
}
