import {useAppContext} from "../../../../context/Context";
import {ACTION} from "../../../../context/actionTypes";
import GenericModal from "../../../../utils/GenericModal";
import useCompanies from "../../../../hooks/companies/useCompanies";
import {IoWarning} from "react-icons/io5";
import {useState} from "react";
import {DropDownMenuComponent} from "../../../../utils/FormComponents";
import api from "../../../../utils/api";
import {setBottomBarError, setBottomBarProcessing} from "../../../../utils/Functions";


export default function ReEmbedDocumentsModal() {
    const [, dispatch] = useAppContext();
    const toggleMe = () => dispatch({type: ACTION.SET_GENERIC_MODAL, payload: null});

    const [{companies}] = useCompanies(false);
    const [selectedCompany, setSelectedCompany] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({});


    function handleSelectCompany(event) {
        const {name, value} = event.target;
        if (name === "company") {
            setSelectedCompany(value);
        }
    }

    function validateForm() {
        const currentErrors: Record<string, string> = {};
        if (!selectedCompany) {
            currentErrors.company = "L'entreprise est requise";
        }
        return currentErrors;
    }

    async function functionHandleConfirm() {
        const currentErrors = validateForm();
        setErrors(currentErrors);
        if (Object.keys(currentErrors).length > 0) return;

        setLoading(true);
        try {
            await api.post(`/document/reembed/${selectedCompany.id}`);
        } catch (error) {
            console.error(`Could not reembed documents for company ${selectedCompany}`, error);
            setBottomBarError(dispatch, error);
            setLoading(false);
            return;
        }

        toggleMe();
        setBottomBarProcessing(dispatch, `La commande de régénération des embeddings des documents de l'entreprise ${selectedCompany.name} a été envoyée`);
    }

    return <GenericModal
        className="w-1/2 xl:w-1/3 2xl:w-1/4 text-base text-black rounded-lg"
        showCloseButton={true}
        exitClick={false}
        toggleMe={toggleMe}
    >
        <div className="font-semibold w-full text-left px-4 py-3 border-b text-sm flex items-end gap-1">
            <IoWarning className="text-red-400 w-5 h-5"/>Régénération des embeddings
        </div>

        <div className="p-8 flex flex-col gap-4">
            <div>
                <DropDownMenuComponent
                    label="Entreprise"
                    name="company"
                    items={companies}
                    labelField="name"
                    onItemSelected={handleSelectCompany}
                    currentItem={selectedCompany}
                    error={errors.company}
                    disabled={loading}
                />
            </div>

            <div>
                Vous vous apprétez à régénérer les embeddings de <b>tous</b> les documents de l'entreprise {selectedCompany ?
                <b>{selectedCompany.name}</b> : "sélectionnée"}.
                Cette opération sera effectuée immédiatement et rendra temporairement la base de connaissances de l'entreprise inacessible.
            </div>
        </div>

        <div className="flex w-full justify-end gap-4 py-2 border-t px-4">
            {loading
                ? <div>Envoi de la commande en cours...</div>
                : <>
                    <button onClick={toggleMe} className="rounded border bg-white text-black text-sm font-light py-1 px-2">
                        Annuler
                    </button>
                    <button onClick={functionHandleConfirm}
                            className="rounded bg-gray-600 hover:bg-gray-700 text-white text-sm py-1 px-2 transform duration-300">
                        Confirmer
                    </button>
                </>
            }
        </div>
    </GenericModal>;
}