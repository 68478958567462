import { useAppContext } from '../../context/Context';
import { ACTION } from '../../context/actionTypes';
import {setBottomBarError, setBottomBarSucces} from "../../utils/Functions";
import GenericModal from '../../utils/GenericModal';
import api from '../../utils/api';
import RagContentDisplay from './RagContentDisplay';

export const getLinkFile = async (source_id, dispatch) => {
    try {
        const response = await api.get(`/document/display/${source_id}`);
        const presignedUrl = response.data.presigned_url;
        window.open(presignedUrl, '_blank');
        setBottomBarSucces(dispatch, "Le document a été ouvert, vous devrez peut-être autoriser les fenêtres popup afin de le voir");
    }
    catch (error) {
        console.error("Failed to download document", error);
        setBottomBarError(dispatch, error);
    }
}


export default function DisplayRagModal(props) {
    const { chunk, source, page, source_id } = props
    const [, dispatch] = useAppContext()

    return (
        <GenericModal
            showCloseButton={true}
            exitClick={true}
            blurBackGround={true}
            toggleMe={() => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })}
        >
            <RagContentDisplay chunk={chunk} source={source} source_id={source_id} page={page} />

        </GenericModal>
    )
}
