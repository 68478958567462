import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';
import { useAppContext } from '../../context/Context';
import { setBottomBarError } from '../../utils/Functions';

export default function useCompanies(withUsage: boolean = true) {
  const [, dispatch] = useAppContext();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    loadCompanies();
  }, [withUsage]);

  const loadCompanies = async () => {
    try {
      const responseCompanies = await api.get('/company/all');
      const responseCompaniesObject = responseCompanies.data;
      if (withUsage) {
        const responseStorage = await api.get('/company/countAll/Usage');
        const responseStorageObject = responseStorage.data;
        responseCompaniesObject.forEach(element => {
          element["int_size"]=responseStorageObject[element.id]
        });
      }
      setCompanies(responseCompaniesObject);
    } catch (error) {
      console.error('Failed to load companies', error);
      setBottomBarError(dispatch, error);
    }
  };

  const createCompany = async () => {
    dispatch({
      type: ACTION.SET_GENERIC_MODAL,
      payload: {
        props: { reload: loadCompanies },
        action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_COMPANY,
      },
    });
  };

  return [{ companies }, { loadCompanies, createCompany }];
}
