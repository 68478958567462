import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';
import { GENERIC_MODAL_ACTIONS, ACTION } from '../../../context/actionTypes';
import useDocuments from '../../../hooks/files/useDocuments';
import ListDisplayer from '../../../utils/ListDisplayer';
import {humanFileSize, setBottomBarError, setBottomBarSucces, setBottomBarProcessing} from '../../../utils/Functions';
import { MdOutlineSearch } from 'react-icons/md';
import { IoReload } from 'react-icons/io5';
import { ImSpinner2 } from 'react-icons/im';
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/Context';
import { getLinkFile } from '../../chat/DisplayRagModal';
import './Documents.css';
import {FaRedo} from "react-icons/fa";
import api from "../../../utils/api";
import {GrDownload, GrView} from "react-icons/gr";
import {buildFullPath} from "../../../utils/url";
const getStatusEmoji = (status) => {
  switch (status?.toUpperCase()) {
    case 'PENDING':
    case 'PROCESSING':
    case 'EMBEDDING':
    case 'INDEXING':
    case 'UPLOADING':
    case 'DELETING':
      return <ImSpinner2 className='animate-spin' />;
    case 'PARTIAL':
      return (
        <div className='flex items-center gap-2'>
          <div className='rounded-full bg-response-medium h-2 w-2' />
          En cours
        </div>
      );
    case 'MAINTENANCE':
      return (
        <div className='flex items-center gap-2'>
          <div className='rounded-full bg-response-medium h-2 w-2' />
          Maintenance
        </div>
      );
    case 'DELETED':
    case 'ERROR':
    case 'FAIL':
      return (
        <div className='flex items-center gap-2'>
          <div className='rounded-full bg-response-warning h-2 w-2' />
          Erreur
        </div>
      );
    default:
      return (
        <div className='flex items-center gap-2'>
          <div className='rounded-full bg-response-positive h-2 w-2' />
          Traité
        </div>
      );
  }
};

export default function Documents() {
  const [state, dispatch] = useAppContext();

  console.log('Current state from useAppContext:', state);

  const [
    { documents, search, moreFilters, filters, displayedDocuments,columnsOnSummary },
    {
      createDocument,
      loadDocuments,
      setSearch,
      setMoreFilters,
      setFilters,
      searchName,
      setdisplayedDocuments,
      dropHiddenMenu,
      hideDropMenu,
      updateColumns,
      setcolumnsOnSummary
    },
  ] = useDocuments();

  console.log('Loaded documents:', documents);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleSelectDocument = (id) => {
    setSelectedDocuments((prev) =>
      prev.includes(id) ? prev.filter((docId) => docId !== id) : [...prev, id]
    );
    console.log('Selected documents after change:', selectedDocuments);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {

      const allDocumentIds = displayedDocuments.map((doc) => {

        return doc.id;
      });
      setSelectedDocuments(allDocumentIds);
      console.log('All documents selected:', displayedDocuments);
    } else {
      setSelectedDocuments([]);
      console.log('Selection cleared.');
    }
  };

  const downloadSingleDocument = async (documentId) => {
    try {
      const response = await api.get(`/document/access/${documentId}`);
      const presignedUrl = response.data.presigned_url;
      window.location.href = presignedUrl;
      setBottomBarProcessing(dispatch, "Téléchargement en cours...");
    } catch (error) {
      console.error("Failed to download document", error);
      setBottomBarError(dispatch, error);
    }
  }

  const downloadMultipleDocuments = async (documentIds: number[]) => {
    try {
      // Navigate to the download page
      window.location.href = buildFullPath(process.env.REACT_APP_BASE_API_URL, `/document/download/${documentIds.join("-")}`);
      setBottomBarProcessing(dispatch, "Téléchargement en cours...");
    } catch (error) {
      console.error("Failed to download documents", error);
      setBottomBarError(dispatch, error);
    }
  }

  const handleDownloadFiles = (documentId)=> {
    if (selectedDocuments.length > 1) {
      downloadMultipleDocuments(selectedDocuments);
    } else {
      if (selectedDocuments.length === 1) {
        documentId = selectedDocuments[0];
      }
      downloadSingleDocument(documentId);
    }
  }

  const handleDeleteSelected = (documentId) => {
    let documentsToDelete =
      selectedDocuments.length > 0 ? selectedDocuments : [documentId];
    console.log('Opening DeleteDocumentModal with IDs:', documentsToDelete);

    dispatch({
      type: ACTION.SET_GENERIC_MODAL,
      payload: {
        action: GENERIC_MODAL_ACTIONS.DELETE_DOCUMENT,
        props: {
          ids: documentsToDelete,
          names: displayedDocuments
            .filter((doc) => documentsToDelete.includes(doc.id))
            .map((doc) => doc.name || 'Document sans nom'),
          reload: () => {
            loadDocuments();
            setSelectedDocuments([]); // Clear selection after reload
          },
        },
      },
    });
  };

  const retryDocument = async (documentId, errors) => {
    console.debug("Retry document " + documentId);
    try {
      const response = await api.post(`/document/${documentId}/retry`);
    } catch (error: any) {
      // TODO : à revoir SCRUM-608
      errors.push(error.response?.data?.detail || error.response?.data || JSON.stringify(error));
    }
  }

  const handleRetryDocumentUpload = async (documentId) => {
    const errors = [];
    setBottomBarProcessing(dispatch, "En cours de traitement...");
    if (selectedDocuments.length > 0) {
      const promises = [];
      for (let document of displayedDocuments) {
        if (selectedDocuments.includes(document.id) && document.status === "fail") {
          promises.push(retryDocument(document.id, errors));
        }
      }

      await Promise.all(promises);
    } else {
      await retryDocument(documentId, errors);
    }

    if (errors.length > 0) {
      setBottomBarError(dispatch, errors.join(", "));
    } else {
      setBottomBarSucces(dispatch, "Les embeddings vont être régénérés");
    }
    loadDocuments();
  }

  useEffect(() => {

  setcolumnsOnSummary([
    {
      label: (
        <input
          type='checkbox'
          className='checkbox-keep-border'
          onChange={handleSelectAll}
          checked={
            displayedDocuments.length > 0 &&
            selectedDocuments.length === displayedDocuments.length
          }
        />
      ),
      key: 'select',
      colSpan: 1,
      template: (data) => (
        <input
          type='checkbox'
          checked={selectedDocuments.includes(data.id)}
          onChange={() => handleSelectDocument(data.id)}
        />
      ),
    },
    {
      label: 'Nom',
      key: 'name',
      shown: true,
      colSpan: 10,
      template: (data) => (data.name ? data.name.toString() : 'N/A'),
    },
    {
      label: 'Statut',
      key: 'embedding_collection',
      shown: true,
      colSpan: 2,
      template: (data) => (
        <div
          data-tooltip-id='documents-tooltip'
          data-tooltip-content={data.status_message || ''}
        >
          <div className='pl-4'>{getStatusEmoji(data.status)}</div>
        </div>
      ),
    },
    {
      label: 'Taille',
      key: 'int_size',
      shown: true,
      colSpan: 1,
      template: (data) => humanFileSize(data.int_size) || 'N/A',
    },
    {
      label: 'Ajouté le',
      key: 'created_at',
      type: 'date',
      shown: true,
      colSpan: 2,
      template: (data) =>
        data.created_at
          ? new Date(data.created_at).toLocaleDateString()
          : 'N/A',
    },
    {
      label: 'Pages',
      key: 'pages',
      colSpan: 1,
    },
  ]);
}, [displayedDocuments,selectedDocuments])

  const actionsMenu = [
    {
      action: (data) => {
        console.log('Downloading document with ID:', data.id);
        getLinkFile(data.id, dispatch);
      },
      label: 'Aperçu',
      enabled: (e) => selectedDocuments.length === 0,
      Icon: GrView,
    },
    {
      action: (data) => {
        console.log('Downloading document with ID:', data.id);
        handleDownloadFiles(data.id);
      },
      label: 'Télécharger',
      enabled: (e) => true,
      Icon: GrDownload,
    },
    {
      action: (data) => handleDeleteSelected(data.id),
      label: 'Supprimer',
      enabled: (e) => {
        const invalidStatuses = ["pending", "processing", "uploading", "embedding", "indexing", "deleting"];
        if (selectedDocuments.length > 0) {
          for (let document of displayedDocuments) {
            if (selectedDocuments.includes(document.id) && invalidStatuses.includes(document.status)) {
              return false;
            }
          }
          return true;
        }
        return !invalidStatuses.includes(e.status);
      },
      Icon: FiTrash,
    },
    {
      action: (data) => handleRetryDocumentUpload(data.id),
      label: 'Réessayer',
      enabled: (e) => {
        if (selectedDocuments.length > 0) {
          for (let document of displayedDocuments) {
            if (selectedDocuments.includes(document.id) && document.status === "fail") {
              return true;
            }
          }
          return false;
        }
        return e.status === "fail";
      },
      Icon: FaRedo,
    },
  ];


  return (
    <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base'>
      <div className='flex items-center justify-between'>
        <div className='font-bold text-2xl text-black'>Documents</div>
        <div className='flex justify-end gap-2'>
          <button
            className='btn-transition bg-perso-blue hover:bg-opacity-75 mb-4'
            onClick={() => {
              console.log('Creating a new document');
              createDocument();
            }}
          >
            <AiOutlinePlusCircle className='h-5 w-5' />
            <div>Ajouter des fichiers</div>
          </button>
          <button
            className='btn-transition bg-perso-blue hover:bg-opacity-75 mb-4'
            onClick={() => {
              console.log('Reloading documents');
              loadDocuments();
              setSelectedDocuments([]); // Clear selection after reload
            }}
          >
            <IoReload className='h-5 w-5' />
            <div>Rafraîchir la liste</div>
          </button>
        </div>
      </div>
      <div className='toolBarDocs'>
        <div className='searchNameClass text-black relative mb-5 mt-5'>
          <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none text-gray-700'>
            <MdOutlineSearch className='w-6 h-6' />
          </div>
          <input
            type='search'
            id='default-search'
            className='block w-80 p-4 ps-10 text-sm text-gray-700 border border-perso-darkBlue-300 rounded-lg bg-gray-50 '
            placeholder='Rechercher par nom'
            onChange={searchName}
          />
        </div>
        <button
          id="dropdownSearchButton"
          data-dropdown-toggle="dropdownSearch"
          className=" h-10 max-w-40 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-virteem-darkBlueLight  focus:ring-4 focus:outline-none focus:ring-virteem-darkBlueLight  dark:bg-gray-700 dark:hover:bg-virteem-darkBlueLight dark:focus:ring-virteem-darkBlueLight "
          type="button"
          onClick={dropHiddenMenu}
        >
          Filtre
          <svg
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownSearch"
          className="z-99 absolute hidden bg-white rounded-lg shadow w-60 dark:bg-white-700 "
        >
          <ul
            className=" h-36 pt-3 px-3 pb-3  overflow-y-auto text-sm "
            aria-labelledby="dropdownSearchButton"
          >
            {columnsOnSummary.slice(2).map((element,index) => {
              return (
                <li>
                  <div className="flex items-center p-2 rounded text-black hover:bg-gray-600 hover:text-white">
                    <input
                      id={"" + index}
                      type="checkbox"
                      className=" w-4 h-4  bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                      defaultChecked={true}
                      onClick={(e)=>updateColumns(e,columnsOnSummary)}
                    />
                    <label
                      htmlFor={"" + element.label}
                      className="w-full ms-2 text-sm font-medium   rounded "
                    >
                      {element.label}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <ListDisplayer
        columnsOnSummary={columnsOnSummary}
        items={displayedDocuments}
        reload={loadDocuments}
        actionsMenu={actionsMenu}
        defaultSortKey='created_at'
      />
      <Tooltip
        id='documents-tooltip'
        style={{ fontSize: '0.75rem', zIndex: 9999 }}
        place='left'
      />
    </div>
  );
}
